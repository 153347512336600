import axios from "axios";

export default {
  async getRapportini(userID: string) {
    const data = new FormData();
    data.append("limit", "50");
    data.append("offset", "0");
    data.append("where[]", `rapportini_id IN (SELECT rapportini_id FROM rel_rapportini_users WHERE users_id = '${userID}')`);
    data.append("orderby", "rapportini_data DESC, rapportini_ora_inizio DESC");
    //data.append("orderdir", "desc");

    const response = await axios.post("rest/v1/search/rapportini", data, {
      headers: {
        Authorization: process.env.VUE_APP_TOKEN,
      },
    });
    //console.log(response);
    return response;
  },

  async getCustomers() {
    const data = new FormData();
    data.append("where[customers_type]", "1");
    data.append("orderby", "customers_full_name");
    data.append("orderdir", "asc");

    const response = await axios.post("rest/v1/search/customers", data, {
        headers: {
            Authorization: `${process.env.VUE_APP_TOKEN}`,
        },
    });
    return response;
  },

  async getDipendenti() {
    const data = new FormData();
    data.append("where[dipendenti_attivo]", "1");
    data.append("orderby", "dipendenti_cognome");
    data.append("orderdir", "asc");

    const response = await axios.post(`rest/v1/search/dipendenti`, data, {
        headers: {
            Authorization: process.env.VUE_APP_TOKEN,
        },
    });
    //console.log(response);
    return response;
},

  async getCommesseByCustomer(customerId: string) {
    const data = new FormData();
    data.append("where[]", `projects_customer_id = ${customerId}`);
    data.append("orderby", "projects_name");
    data.append("orderdir", "asc");

    const response = await axios.post("rest/v1/search/projects", data, {
        headers: {
            Authorization: `${process.env.VUE_APP_TOKEN}`,
        },
    });
    return response;
  },

  async getCategorieFoto() {
    const response = await axios.get("rest/v1/search/rapportini_immagini_categoria", {
        headers: {
            Authorization: `${process.env.VUE_APP_TOKEN}`,
        },
    });
    //console.log(response);
    return response;
},

  async saveRapportino(data) {
    const response = await axios.post("rest/v1/create/rapportini", data, {
      headers: {
        Authorization: process.env.VUE_APP_TOKEN,
      },
    });
    //console.log(response);
    return response;
  },

  async salvaFotoRapportino(immagini, rapportino) {
    const data = new FormData();
    data.append("immagini", JSON.stringify(immagini));
    data.append("rapportino_id", rapportino);

    //const response = await axios.post(`http://192.168.11.141/firegui_crm/rapportini/rapportini/savePhotos`, data);
    const response = await axios.post(`${process.env.VUE_APP_BASE_URL}rapportini/rapportini/savePhotos`, data);
    //console.log(response);
    return response;
  },

  async getImmagini(rapportino: string) {
      const data = new FormData();
      data.append("where[]", `rapportini_immagini_rapportino_id = '${rapportino}'`);
      data.append("orderby", "rapportini_immagini_creation_date");
      data.append("orderdir", "desc");

      const response = await axios.post("rest/v1/search/rapportini_immagini", data, {
          headers: {
              Authorization: `${process.env.VUE_APP_TOKEN}`,
          },
      });
      return response;
  },
};
