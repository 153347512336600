
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonTitle, IonIcon, IonButton, modalController } from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";
import { useRoute } from "vue-router";
import { reactive, ref, onMounted } from "vue";
import { openToast } from "@/services/toast";

export default {
    name: "FirmaRapportino",
    props: {
        data: {
            type: String,
            required: true,
        },
        firma: {
            type: String,
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonToolbar,
        IonButtons,
        IonTitle,
        IonIcon,
        IonButton,
    },
    setup(props) {
        const route = useRoute();
        const reference = props.data === "tecnico" ? "tecnico" : "cliente";

        const canvas = ref<HTMLCanvasElement | null>(null);
        const ctx = ref<CanvasRenderingContext2D | null>(null);

        const hasSigned = ref(false);
        const moving = ref(false);
        const lastX = ref(0);
        const lastY = ref(0);
        
        const firma_da_cancellare = ref(false);

        const initializeCanvas = () => {
            const canvasElement = canvas.value;
            if (!canvasElement) return;

            canvasElement.width = window.innerWidth;
            canvasElement.height = window.innerHeight;

            ctx.value = canvasElement.getContext("2d");
            if (ctx.value) {
                ctx.value.strokeStyle = "#000";
                ctx.value.lineJoin = "round";
                ctx.value.lineCap = "round";
                ctx.value.lineWidth = 3;
            }

            populateCanvas();
        };

        const populateCanvas = () => {
            if (props.firma && props.firma.length > 0 && canvas.value) {
                const sign = new Image();
                sign.onload = () => {
                    if (ctx.value) {
                        ctx.value.drawImage(sign, 0, 0);
                    }
                };
                sign.src = `data:image/png;base64,${props.firma}`;
            }
        };

        const closeModal = async () => {
            await modalController.dismiss(firma_da_cancellare.value ? { da_cancellare: true, tipo: reference } : undefined);
        };

        /* const getMousePos = (event) => {
            const canvasElement = canvas.value;
            if (!canvasElement) return [0, 0];

            const rect = canvasElement.getBoundingClientRect();
            const clientX = event.touches ? event.touches[0].clientX : event.clientX;
            const clientY = event.touches ? event.touches[0].clientY : event.clientY;

            return [clientX - rect.left, clientY - rect.top];
        }; */
        const getMousePos = (event) => {
            const canvasElement = canvas.value;
            if (!canvasElement) return [0, 0];

            const rect = canvasElement.getBoundingClientRect();
            const clientX = event.touches ? event.touches[0].clientX : event.clientX;
            const clientY = event.touches ? event.touches[0].clientY : event.clientY;

            // Calcola le coordinate del tocco/mouse in relazione alla dimensione del canvas
            const x = (clientX - rect.left) * (canvasElement.width / rect.width);
            const y = (clientY - rect.top) * (canvasElement.height / rect.height);

            return [x, y];
        };


        const mStart = (e: TouchEvent) => {
            e.preventDefault();

            hasSigned.value = true;

            [lastX.value, lastY.value] = getMousePos(e);
            moving.value = true;
        };

        const mMove = (e: TouchEvent) => {
            if (!moving.value || !ctx.value) return;
            
            e.preventDefault();

            const [x, y] = getMousePos(e);
            ctx.value.beginPath();
            ctx.value.moveTo(lastX.value, lastY.value);
            ctx.value.lineTo(x, y);
            ctx.value.stroke();

            [lastX.value, lastY.value] = [x, y];
        };

        const mEnd = (e: TouchEvent) => {
            if (moving.value) {
                e.preventDefault();
                moving.value = false;
            }
        };

        const clearCanvas = () => {
            if (ctx.value && canvas.value) {
                ctx.value.clearRect(0, 0, canvas.value.width, canvas.value.height);
            }
            hasSigned.value = false;
            firma_da_cancellare.value = true;
        };

        const getCanvas = () => canvas.value!.toDataURL();

        const closeModalOnSubmit = async () => {
            if (hasSigned.value) {
                const sign = getCanvas().replace("data:image/png;base64,", "");
                if (sign) {
                    await modalController.dismiss({ firma: sign, tipo: reference });
                } else {
                    await modalController.dismiss();
                }
            } else {
                openToast("Non puoi confermare senza aver firmato", "toast_danger");
            }
        };

        const confermaFirme = () => {
            const sign = getCanvas().replace("data:image/png;base64,", "");
            if (sign) closeModalOnSubmit();
        };

        const setCanvasSize = () => {
            const canvasElement = canvas.value;
            if (!canvasElement) return;

            const parentElement = canvasElement.parentElement;
            if (parentElement) {
                canvasElement.width = parentElement.clientWidth;
                canvasElement.height = parentElement.clientHeight;
            }

            ctx.value = canvasElement.getContext("2d");
            if (ctx.value) {
                ctx.value.strokeStyle = "#000";
                ctx.value.lineJoin = "round";
                ctx.value.lineCap = "round";
                ctx.value.lineWidth = 3;
            }
        };


        onMounted(() => {
            setCanvasSize();
            initializeCanvas();
        });

        return {
            canvas,
            mStart,
            mMove,
            mEnd,
            clearCanvas,
            getCanvas,
            arrowBackOutline,
            closeModal,
            reference,
            confermaFirme,
        };
    },
};
