<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Nuovo rapportino</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">

                <!-- Customer list, triggered with click on select client in form -->
                <Transition name="fade-transition">
                    <div v-show="showCustomer" class="customer_modal">
                        <div class="customers_container">
                            <input type="text" placeholder="Cerca cliente" v-model="searchQueryCustomers" class="search_query_input" />
                            <div class="searched_result_list">
                                <div
                                    v-for="customer in searchedCustomers"
                                    :key="customer.customers_id"
                                    @click="setSelectedCustomer(customer)"
                                    class="product"
                                    :class="{ 'selected_product': isSelectedCustomer(customer) }"
                                >
                                    {{ customer.customers_company ? customer.customers_company : `${customer.customers_name} ${customer.customers_last_name}` }}
                                </div>
                            </div>
                        </div>
                        <div @click="closeCustomerSelection()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <!-- Products selection, triggered with click on select client in form -->
                <Transition name="fade-transition">
                    <div v-show="showProductsModal" class="customer_modal">
                        <div class="customers_container">
                            <input type="text" placeholder="Cerca prodotto" v-model="searchQuery" class="search_query_input" />
                            <div v-if="searchedProducts.length > 0" class="searched_result_list">
                                <div
                                    v-for="product in searchedProducts"
                                    :key="product.fw_products_id"
                                    @click="setSelectedProduct(product)"
                                    class="product"
                                    :class="{ 'selected_product': isSelectedProduct(product) }"
                                >
                                    {{product.fw_products_name}}
                                </div>
                            </div>
                            <div v-else class="searched_result_list no_result">
                                Nessun prodotto associato alla commessa selezionata
                            </div>
                        </div>
                        <div @click="closeProductsSelection()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <form @submit.prevent="createRapportino()">
                    <ion-list class="fields">
                        <!-- Cliente -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title customer_selection" position="floating">
                                    <span>
                                        Cliente
                                    </span>
                                    <div @click="openCustomerSelection()" class="trigger">seleziona</div>
                                </div>
                                <div class="value">
                                    <div v-if="selectedCustomer">
                                        {{
                                            selectedCustomer.customers_company
                                                ? selectedCustomer.customers_company
                                                : `${selectedCustomer.customers_name} ${selectedCustomer.customers_last_name}`
                                        }}
                                    </div>
                                    <div v-else class="no_data">Nessun cliente selezionato</div>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Commessa -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Commessa
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div v-if="selectedCustomer" class="value" @click="handleCheckCustomer()">
                                    <ion-select v-model="newRapportino.commessa" @ionChange="setSelectedCommessa($event.target.value)">
                                        <ion-select-option v-for="commessa in commesse" :key="commessa.projects_id" :value="commessa.projects_id">
                                            {{ `${commessa.projects_name}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                                <div v-else class="value no_data">Seleziona un cliente per scegliere la commessa</div>
                            </div>
                        </ion-item>

                        <!-- Data inizio -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Data
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="data"
                                        display-format="DD/MM/YYYY"
                                        picker-format="DD/MM/YYYY"
                                        min="2024"
                                        max="2099"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="newRapportino.data"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ora inizio -->
                        <div class="flex_container">
                            <div class="field">
                                <div class="field_title">
                                    Ora inizio
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_inizio"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="newRapportino.ora_inizio"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                            <div class="field">
                                <div class="field_title">
                                    Ora fine
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_fine"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="newRapportino.ora_fine"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </div>

                        <!-- Operatori -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Operatori
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-select name="tecnici" multiple="true" cancel-text="Annulla" ok-text="Conferma" v-model="newRapportino.operatori">
                                        <ion-select-option v-for="tecnico in tecnici" :key="tecnico.users_id" :value="tecnico.users_id">
                                            {{ `${tecnico.users_last_name} ${tecnico.users_first_name}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Prodotti -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title product_selection_trigger">
                                    Prodotti 
                                    <div @click="openProductsSelection()" class="trigger">seleziona</div>
                                </div>
                                <div class="value">
                                    <div v-if="selectedProducts.length != 0">
                                        <div v-for="(prod, index) in selectedProducts" :key="index">
                                            {{ prod.fw_products_name }}
                                        </div>
                                    </div>
                                    <div v-else>Nessun prodotto selezionato</div>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Descrizione -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Note
                                </div>
                                <div class="value">
                                    <ion-textarea
                                        type="text"
                                        rows="4"
                                        v-model="newRapportino.descrizione"
                                        placeholder="Note lavoro svolto"
                                        class="custom_input"
                                    >
                                    </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Foto  -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Foto
                                </div>
                                <div class="value">
                                    <div v-if="fotoRapportino.length == 0" class="no_data">
                                        Nessuna foto selezionata
                                    </div>
                                    <div v-else>
                                        <div class="foto_container">
                                            <div class="single_foto" v-for="(foto, index) in fotoRapportino" :key="index">
                                                <ion-thumbnail>
                                                    <img :src="`data:image/jpeg;base64,${foto}`" />
                                                </ion-thumbnail>
                                                <div class="remove_photo" @click="removePhoto(index)">Rimuovi</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ion-item>

                        <div class="actions_firme">
                            <button :disabled="!richiediFirmaCliente" type="button" class="btn_firma" :class="{ btn_signed: customerHasSigned }" @click="openSignature('cliente')">
                                Firma cliente
                            </button>
                            <button :disabled="!richiediFirmaTecnico" type="button" class="btn_firma" :class="{ btn_signed: technicianHasSigned }" @click="openSignature('tecnico')">
                                Firma tecnico
                            </button>
                        </div>

                    <div class="action">
                        <div>
                            <button type="button" class="btn_carica_foto" @click="openFilePicker">Seleziona foto</button>
                            <input ref="fileInput" type="file" @change="handleFileChange" accept="image/*" multiple hidden />
                        </div>
                        <button type="submit" class="btn_crea_rapportino" :disabled="isCreatingRapportino">Salva</button>
                    </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonDatetime,
    IonTextarea,
    IonText,
    // IonInput,
    IonSelect,
    IonSelectOption,
    IonIcon,
    IonThumbnail
} from "@ionic/vue";
import { arrowBackOutline, close } from "ionicons/icons";

import { defineComponent, ref, reactive, computed, watch, onMounted } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import moment from "moment";

import { openToast } from "@/services/toast";

//import apiClienti from "@/services/clienti";
//import apiProgetti from "@/services/progetti";
//import apiTickets from "@/services/tickets";
import apiTecnici from "@/services/tecnici";
import apiRapportini from "@/services/rapportini";
import apiProdotti from "@/services/prodotti";

//import ModalFirmaIntervento from "@/components/firme/ModalFirmaIntervento";
import FirmaRapportino from "@/components/firme/FirmaRapportino";

export default defineComponent({
    name: "NuovoRapportino",
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonDatetime,
        IonTextarea,
        IonText,
        // IonInput,
        IonSelect,
        IonSelectOption,
        IonIcon,
        IonThumbnail
    },
    setup(props, context) {
        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;

        const start_date = new Date();
        const start_hour = new Date();
        const end_hour = new Date();
        start_hour.setHours(start_hour.getHours() - 1);
        end_hour.setHours(end_hour.getHours() + 2);


        // Convert time field to ISO format
        function createDateFromTimeString(timeString) {
            const currentDate = moment();
            const dateString = currentDate.format('YYYY-MM-DD');
            const dateTimeString = `${dateString} ${timeString}`;

            return moment(dateTimeString, 'YYYY-MM-DD HH:mm').toISOString();
        }

        const newRapportino = reactive({
            cliente: null,
            sede_cliente: null,
            commessa: null,
            ticket: null,
            data: start_date.toISOString(),
            ora_inizio: start_hour.toISOString(),
            ora_fine: end_hour.toISOString(),
            /* ore_fatturabili: "",
            tipo: "",
            stato_lavori: "", */
            operatori: [],
            note: null,
            firma_tecnico: null,
            firma_cliente: null,
            prodotti: [],
        });

        const successResponse = ref(false);

        // Gestione commessa
        const currentCommessa = ref(null);
        const richiediFirmaCliente = ref(false);
        const richiediFirmaTecnico = ref(false);

        /**
         * 
         * ! Open modal to sign for technician and customer
         * 
         */
        const technicianHasSigned = ref(false);
        const customerHasSigned = ref(false);
        async function openSignature(signatureType) {
            const modal = await modalController.create({
                component: FirmaRapportino,
                componentProps: {
                    data: signatureType,
                    firma: signatureType === "tecnico" ? newRapportino.firma_tecnico : newRapportino.firma_cliente,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //console.log(detail.data);
                if (detail.data) {
                    // Signed, change button clas
                    if (detail.data.firma && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            newRapportino.firma_tecnico = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                technicianHasSigned.value = true;
                            }
                        } else {
                            newRapportino.firma_cliente = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                customerHasSigned.value = true;
                            }
                        }
                    }
                    // Removed signature or closed modal, remove signature from rapportino
                    if (detail.data.da_cancellare === true && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            newRapportino.firma_tecnico = null;
                            technicianHasSigned.value = false;
                        } else {
                            newRapportino.firma_cliente = null;
                            customerHasSigned.value = false;
                        }
                    }
                }
            });
            return modal.present();
        }

        /**
         * 
         * ! Load products data
         * ! Products selection on custom modal
         * 
         */

        const prodotti = ref([]);
        const selectedProducts = ref([]);
        
        async function loadProdotti(commessaId) {
            // Svuoto evenuali prodotti selezionati
            selectedProducts.value = [];
            try {
                const res = await apiProdotti.getProdottiCommessa(commessaId);
                console.log(res);
                if(res.status === 200 && res.data.status === 0) {
                    prodotti.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta dei prodotti", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta dei prodotti", "toast_danger");
            }
        }

        const showProductsModal = ref(false);
        function openProductsSelection() {
            showProductsModal.value = true;
        }

        function closeProductsSelection() {
            showProductsModal.value = false;
        }

        const searchQuery = ref("");
        const normalizeStringProduct = (str) => str.toLowerCase().replace(/ /g, "");

        const searchedProducts = computed(() => {
            const term = normalizeStringProduct(searchQuery.value);

            return prodotti.value.filter((product) => {
                return product.fw_products_name.includes(term);
            });
        });

        
        function setSelectedProduct(product) {
            if (product) {
                const index = selectedProducts.value.findIndex(p => p.fw_products_id === product.fw_products_id);

                if (index === -1) {
                    // Aggiunro prodotto
                    searchQuery.value = "";
                    selectedProducts.value.push(product);
                } else {
                    // Rimuovo prodotto
                    selectedProducts.value.splice(index, 1);
                }
            }
        }

        const isSelectedProduct = (product) => {
            return selectedProducts.value.some(p => p.fw_products_id === product.fw_products_id);
        };

        /**
         * 
         * ! Close modal after form data submit
         * 
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * 
         * ! Handle close page
         * 
         */
        const closeModal = () => {
            modalController.dismiss();
        };


        /**
         * 
         * ! Load technicians data
         * 
         */
        const tecnici = ref([]);
        async function loadTecnici() {
            try {
                const res = await apiTecnici.getTecnici();

                if(res.status === 200 && res.data.status === 0) {
                    tecnici.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta dei tecnici", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta dei tecnici", "toast_danger");
            }
        }

        /**
         * 
         * ! Load customers data
         * 
         */
        const customers = ref([]);
        async function loadCustomers() {
            try {
                const res = await apiRapportini.getCustomers();

                if(res.status === 200 && res.data.status === 0) {
                    customers.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta dei clienti", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta dei clienti", "toast_danger");
            }
        }


        /*******************************************
        *
        * ! Ricerca commessa in base al cliente scelto
        *
        ********************************************/
        const commesse = ref([]);
        async function loadCommesse(customerId) {
            try {
                const res = await apiRapportini.getCommesseByCustomer(customerId);
                //console.log(res.data.data);

                if(res.status === 200 && res.data.status === 0) {
                    commesse.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta delle commesse", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta delle commesse", "toast_danger");
            }
        }


        /*******************************************
        *
        * ! Ricerca e selezione cliente con modale
        *
        ********************************************/
        const showCustomer = ref(false);
        function openCustomerSelection() {
            showCustomer.value = true;
        }
        function closeCustomerSelection() {
            showCustomer.value = false;
        }

        const searchQueryCustomers = ref("");
        const normalizeStringCustomer = (str) => {
            return str.toLowerCase().replace(/ /g, "");
        };

        const matchesSearchTerm = (term, ...values) => {
            return values.some(value => normalizeStringCustomer(value).indexOf(term) !== -1);
        };

        const searchedCustomers = computed(() => {
            const term = normalizeStringCustomer(searchQueryCustomers.value);
            
            return customers.value.filter(cliente => {
                if (cliente.customers_company) {
                    return matchesSearchTerm(term, cliente.customers_company);
                }

                const name = cliente.customers_name || "";
                const lastName = cliente.customers_last_name || "";

                return matchesSearchTerm(
                    term,
                    name,
                    lastName,
                    lastName + name,
                    name + lastName
                );
            });
        });

        const selectedCustomer = ref(null);
        function setSelectedCustomer(customer) {
            //console.log(customer);
            if (customer) {
                // Svuoto ricerca e chiudo modale
                searchQuery.value = "";
                showCustomer.value = false;
                // Immposto cliente selezionato
                selectedCustomer.value = customer;
                newRapportino.cliente = customer.customers_id;
                //Svuoto eventuale selezione precedente della commessa
                newRapportino.commessa = null;
                //selectedCommessa.value = null;
                //Prendo commesse del cliente selezionato
                loadCommesse(customer.customers_id);
            }
        }
        const isSelectedCustomer = (customer) => {
            //return customers.value.some(c => c.customers_id === selectedCustomer.value.customers_id);
            return selectedCustomer.value && selectedCustomer.value.customers_id === customer.customers_id;
        };


        /**
         * ! Load photos from input file
         */
        const fotoRapportino = ref([]);
        const fileInput = ref(null);

        const openFilePicker = () => {
            fileInput.value.click();
        };

        const handleFileChange = (event) => {
            const files = event.target.files;
            if (files.length === 0) return;

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const reader = new FileReader();

                reader.onload = (e) => {
                    const base64String = e.target.result.split(',')[1];
                    fotoRapportino.value.push(base64String);
                };

                reader.readAsDataURL(file);
            }
        };

        function removePhoto(index) {
            if(fotoRapportino.value.length > 0) {
                fotoRapportino.value.splice(index, 1)
            }
        }


        function setSelectedCommessa(event) {
            // Removed currentCommessa and firme flags
            currentCommessa.value = null;
            richiediFirmaCliente.value = false;
            richiediFirmaTecnico.value = false;

            // Filtra array commese prendendo quella con projects_id uguale alla commessa selezionata
            const filtered = commesse.value.filter((commessa) => commessa.projects_id === event);
            if(filtered) {
                currentCommessa.value = filtered[0];
                richiediFirmaCliente.value = currentCommessa.value.projects_richiesta_firma_cliente && currentCommessa.value.projects_richiesta_firma_cliente == '1' ? true : false;
                richiediFirmaTecnico.value = currentCommessa.value.projects_richiesta_firma_operatore && currentCommessa.value.projects_richiesta_firma_operatore == '1' ? true : false;
                
                loadProdotti(currentCommessa.value.projects_id);
            }
        }

        function handleCheckCustomer() {
            if(!selectedCustomer.value) {
                openToast("Devi selezionare un cliente prima di poter selezionare una commessa", "toast_danger");
                return;
            }
        }


        /**
         * ! Create new rapportino
         */
        const isCreatingRapportino = ref(false);

        async function createRapportino() {
            // Controllo cliente
            if (!newRapportino.cliente) {
                openToast("Il cliente è obbligatorio", "toast_danger");
                return;
            }
            // Controllo commessa
            if (!newRapportino.commessa) {
                openToast("La commessa è obbligatoria", "toast_danger");
                return;
            }
            //Controllo che ora inizio non sia successiva ad ora fine
            const ora_inizio = new Date(newRapportino.ora_inizio);
            const ora_fine = new Date(newRapportino.ora_fine);
            if (ora_inizio > ora_fine) {
                openToast("L'ora di inizio non può essere maggiore dell'ora di fine", "toast_danger");
                return;
            }
            //Controllo che ci sia almeno un tecnico selezioanto
            if (newRapportino.operatori.length === 0) {
                openToast("Non puoi creare il rapportino senza selezionare gli operatori", "toast_danger");
                return;
            }
            //Controllo di essere sempre tra i operatori selezionati
            const isUSerSelected = newRapportino.operatori.includes(userID);
            if (!isUSerSelected && newRapportino.operatori.length != 0) {
                openToast("Non puoi creare il rapportino senza essere tra gli operatori selezionati", "toast_danger");
                return;
            }

            isCreatingRapportino.value = true;

            const data_inizio_formatted = moment(newRapportino.data_inizio).format("YYYY-MM-DD");
            const ora_inizio_formatted = moment(newRapportino.ora_inizio).format("HH:mm");
            const ora_fine_formatted = moment(newRapportino.ora_fine).format("HH:mm");

            const formData = new FormData();

            formData.append("rapportini_cliente", newRapportino.cliente);
            formData.append("rapportini_commessa", newRapportino.commessa);
            formData.append("rapportini_data", data_inizio_formatted);
            formData.append("rapportini_ora_inizio", ora_inizio_formatted);
            formData.append("rapportini_ora_fine", ora_fine_formatted);

            newRapportino.operatori.forEach((operatore) => {
                formData.append("rapportini_operatori[]", operatore);
            });

            if(newRapportino.note) {
                formData.append("rapportini_note", newRapportino.note);
            }

            if(selectedProducts.value.length > 0) {
                selectedProducts.value.forEach((prod) => {
                    formData.append('rapportini_prodotti[]', prod.fw_products_id);
                });
            }

            if(newRapportino.firma_tecnico) {
                formData.append("rapportini_firma_tecnico", newRapportino.firma_tecnico);
            }
            if(newRapportino.firma_cliente) {
                formData.append("rapportini_firma_cliente", newRapportino.firma_cliente);
            }

            if(fotoRapportino.value.length > 0) {
                fotoRapportino.value.forEach((foto) => {
                    formData.append('rapportini_immagini[]', foto);
                });
            }

            try {
                const response = await apiRapportini.saveRapportino(formData);
                if (response.data.status === 8 || response.data.status === 5) {
                    openToast(response.data.message, "toast_danger");
                } else {
                    const res = response.data.data[0];
                    successResponse.value = true;
                    closeModalOnSubmit(successResponse, res);
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la creazione del rapportino", "toast_danger");
            } finally {
                isCreatingRapportino.value = false;
            }
        }

        onMounted(() => {
            loadCustomers();
            loadTecnici();
        });

        return {
            // Icon
            closeModal,
            arrowBackOutline,
            close,
            // Data
            createRapportino,
            newRapportino,
            customers,
            tecnici,
            // Customers
            selectedCustomer,
            openCustomerSelection,
            closeCustomerSelection,
            showCustomer,
            searchQueryCustomers,
            searchedCustomers,
            setSelectedCustomer,
            isSelectedCustomer,
            //Commesse
            handleCheckCustomer,
            commesse,
            setSelectedCommessa,
            // Signature
            richiediFirmaCliente,
            richiediFirmaTecnico,
            openSignature,
            technicianHasSigned,
            customerHasSigned,
            isCreatingRapportino,
            // Load photos
            fileInput,
            openFilePicker,
            handleFileChange,
            fotoRapportino,
            removePhoto,
            // Products
            prodotti,
            showProductsModal,
            searchQuery,
            searchedProducts,
            selectedProducts,
            openProductsSelection,
            closeProductsSelection,
            isSelectedProduct,
            setSelectedProduct,
            loadProdotti
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f2f2f2;
    padding: 16px;
}

.fields {
    width: 100%;
    /*border-radius: 4px;*/
    border-radius: 8px;
    /*padding: 10px;*/
    padding: 16px;
    /*box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);*/
    box-shadow: 0px 2px 5px 0px #00000040;
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.field_title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #000000;
}

.field_title.customer_selection {
    display: flex;
    justify-content: space-between;
}

.field_title.customer_selection .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}

ion-datetime,
ion-select {
    max-width: 100%;
    /* border: 1px solid #d1d5db; */
    border: 1px solid #0000001a;
    border-radius: 6px;
    --padding-start: 12px;
    --padding-end: 12px;
    --padding-left: 12px;
    --padding-right: 12px;
    --placeholder-color: #00000080;
}
ion-select::part(icon) {
    opacity: 1;
    color: #086fa3;
}

ion-input,
ion-textarea {
    --placeholder-color: #00000080;
    --padding-start: 12px !important;
    --padding-end: 12px !important;
    --padding-left: 12px !important;
    --padding-right: 12px !important;
    /* border: 1px solid #d1d5db; */
    border: 1px solid #0000001a;
    border-radius: 6px;
    margin-top: 0;
}
.custom_input textarea::placeholder {
    font-size: 5px;
}

.btn_crea_rapportino,
.btn_carica_foto {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: #086fa3;
    color: #ffffff;
}
.btn_crea_rapportino:disabled {
    touch-action: none;
    opacity: .55;
}
.btn_carica_foto {
    background-color: #94a3b8;
}

ion-button {
    --color: #ffffff;
}

.action {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.actions_firme {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
    gap: 16px;
}
.btn_firma {
    width: 50%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
}

.btn_firma:disabled {
    touch-action: none;
    opacity: .4;
}

.btn_firma.btn_signed {
    background-color: #086fa3;
    color: #ffffff;
    transition: all 0.15s ease-in;
}

/** Allegati */
.btn_allega_foto {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

.foto_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    margin-top: 8px;
    /*margin-bottom: 16px;*/
}
.single_foto {
    margin-right: 16px;
    margin-bottom: 16px;
}
ion-thumbnail {
    --size: 120px;
    --border-radius: 4px;
}

.remove_photo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
}
.flex_container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}
.flex_container .field {
    width: 47%;
}

/**
* Products selection
*/
.field_title.product_selection_trigger {
    display: flex;
    justify-content: space-between;
}

.field_title.product_selection_trigger .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.customer_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 16px;
}
.search_query_input {
    width: 100%;
    border: 1px solid #c3c8cf;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
}
.search_query_input::placeholder {
    color: #64748b;
}
.search_query_input:focus-visible {
    /* outline: 1px solid #509843;*/
    outline: 1px solid #334155;
}
.customers_container {
    width: 100%;
    height: 80%;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
    padding: 12px;
    display: flex;
    flex-direction: column;
}
.searched_result_list {
    overflow-y: scroll;
}
.searched_result_list.no_result {
    text-align: center;
    font-weight: 500;
    color: #64748b;

}
.searched_result_list .product {
    padding: 4px 8px;
    margin-bottom: 8px;
}
.selected_product {
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    font-weight: 500;
}

.no_data {
    font-size: 14px;
}
</style>
